@use 'src/styles/variables';

.App {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.ContentContainer {
  position: relative;
  width: 100%;
  max-width: variables.$canvas-max-width;
  height: 100%;
  max-height: 700px;
}
