$color-green: #9ea700;
$color-yellow: #fbb901;
$color-blue: #00b5d4;
$color-dark-blue: #223343;
$color-bg-blue: #3d5164;
$color-gray: #c4c4c4;
$color-gray-medium-light: #dbdbdb;
$color-gray-light: #f5f5f5;
$color-white: #fff;
$color-extra-dark-blue: #131f2b;
$color-text: #242424;
$color-red: #ff2929;
$color-purple: #b886db;
$color-pink: #ee4187;
$silver: #6d8388;
$bronze: #c28f02;

@mixin color-background-dark {
  background-color: $color-extra-dark-blue;
  color: $color-white;
}
