@import 'src/styles/colors';

.distanceIndicator {
  z-index: 1;
  width: 11rem;
  height: 11rem;
  background: $color-green;
  transform-origin: center;
  transform: rotate(7.41deg);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms background-color;
  position: relative;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: #c1c850;
    }
  }

  .crown {
    position: absolute;
    top: -5rem;
    left: -4.5rem;
  }

  .content {
    transform-origin: center;
    transform: rotate(-7.41deg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .distanceIndicatorSelf {
      border-bottom: 0.5px solid #7a8100;
      padding: 0 0.4rem 0.2rem;
      margin-top: 0.5rem;

      h3 {
        color: $color-text;
      }
    }

    .distanceIndicatorGroup {
      margin-top: 0.2rem;

      h3 {
        color: #757b00;
        font-weight: normal;
      }
    }

    .distanceIndicatorSelf,
    .distanceIndicatorGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        margin-left: 0.5rem;
        min-width: 4.4rem;
        text-align: right;
      }
    }
  }
}
