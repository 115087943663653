@use 'src/styles/variables';
@use 'src/styles/mixins';

.container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: variables.$canvas-max-width;
  margin: 1.6rem;
  margin-bottom: 0;
  margin-right: 4.8rem;

  > button {
    @include mixins.flex-center;

    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;

    > span {
      display: flex;
      margin-left: 0.8rem;
    }
  }
}
