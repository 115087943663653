@use 'src/styles/mixins';
@use 'src/styles/media';

.modal {
  min-height: 281px; // height of the largest content
  max-height: 95%;
}

.body {
  h2 {
    font-size: 1.6rem;
  }
}

.media {
  @include mixins.flex-center;

  justify-content: flex-end;
}

.grid {
  @include mixins.grid(1);

  @media (min-width: media.$tablet) {
    @include mixins.grid(2);
  }
}

.left,
.right {
  position: absolute;
  bottom: 2.4rem;
  height: 52px;
  width: 65px;
}

.left {
  left: -1.6rem;
}

.right {
  right: -1.6rem;
}
