@import 'src/styles/colors';
@import 'src/styles/mixins';

.button {
  @include transition(background-color);

  border: none;
  text-align: center;
  position: relative;
  white-space: nowrap;
  background-color: $color-blue;
  color: $color-white;
  font-size: 1.53rem;
  line-height: 5.1rem;
  font-weight: 700;
  padding: 0 1.7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    outline: none;
    background-color: #7cc8d6;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    max-height: 4rem;

    &.margin > svg {
      margin-left: 1.2rem;
    }
  }

  &:disabled {
    cursor: default;
    background-color: $color-gray;

    &:hover {
      background-color: $color-gray;
    }
  }
}

.textButton {
  border: none;
  text-align: center;
  position: relative;
  white-space: nowrap;
  font-size: 1.53rem;
  line-height: 5.1rem;
  font-weight: 700;
  color: $color-gray;
  padding: 0 1.7rem;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  justify-content: space-between;

  &.active {
    color: $color-white;
  }
}
