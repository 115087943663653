@use 'src/styles/colors';
@use 'src/styles/mixins';

.category {
  display: flex;
}

.icon {
  margin-right: 1.6rem;
  min-width: 3.6rem;
}

.purple {
  color: colors.$color-purple;
}

.yellow {
  color: colors.$color-yellow;
}

.pink {
  color: colors.$color-pink;
}
