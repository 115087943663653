@use 'src/styles/colors';
@use 'src/styles/mixins';

$width: 60.4rem;

.overlay {
  @include mixins.flex-center;

  position: fixed;
  z-index: 9;
  inset: 0;
  background: rgb(68 92 115 / 90%);
}

.modal {
  position: relative;
  background-color: colors.$color-white;
  width: $width;
  max-width: 80%;

  &:focus-visible {
    outline: none;
  }

  &.dark {
    color: colors.$color-white;
    background-color: colors.$color-dark-blue;

    h2 {
      color: colors.$color-white;
    }
  }
}

.headerContainer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: -51px;

  .header {
    display: flex;

    > div {
      position: absolute;
      top: -16px;
    }
  }
}

.label {
  position: absolute;
  top: -2.6rem;
  left: -2.6rem;
}

.body {
  padding: 4rem 8rem;

  &.withLabel {
    padding-left: 11rem;
    padding-right: 5rem;
  }
}

.noPadding {
  .body {
    padding: 0;
  }
}

.actions {
  position: absolute;
  right: -1.6rem;
  bottom: -1.6rem;
}
