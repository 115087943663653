@import 'src/styles/colors';

.closeButton {
  border: none;
  text-align: center;
  position: relative;
  white-space: nowrap;
  background-color: transparent;
  color: rgba($color-white, 0.5);
  font-size: 1.53rem;
  line-height: 5.1rem;
  min-width: 5.1rem;
  min-height: 5.1rem;
  font-weight: 700;
  padding: 0 1.7rem;
  cursor: pointer;
  transition: 300ms opacity;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    margin-left: 1.2rem;
  }
}
