h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: 3.2rem;
  line-height: 1.24;
  font-weight: 600;
  color: $color-white;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.8rem;
  line-height: 1.24;
  margin-bottom: 1.5rem;
  color: $color-dark-blue;
}

h3 {
  font-size: 1.6rem;
  line-height: 1.4;
  margin-bottom: 0;
}

h4 {
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 0;
}

h5 {
  font-size: 1.5rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.4rem;
  line-height: 1.4;
}

p {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-family: Roboto, sans-serif;
}

ul li {
  font-family: Roboto, sans-serif;
}

a {
  color: $color-blue;
}
