@import 'src/styles/colors';

.logoWrapper {
  position: absolute;
  bottom: 4.3rem;
  right: 5.3rem;
  pointer-events: none;

  @media (max-width: 600px) {
    flex-direction: column;
    right: 2.8rem;
    bottom: 1.8rem;
  }

  .logo {
    position: relative;

    svg {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: '';
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgb(34 51 67 / 72%) 0%,
        rgb(34 51 67 / 0%) 100%
      );
      width: 21.6rem;
      height: 12rem;
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      left: 50%;
      top: 50%;
      z-index: 1;
    }
  }

  .subtitle {
    background-color: $color-white;
    bottom: -4.3rem;
    left: 0;
    padding: 0.7rem 2rem;
    position: absolute;
    font-size: 1.53rem;
    font-weight: 700;
    width: 15rem;
    text-align: center;
  }
}
