@import 'colors';

html {
  height: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
}

* {
  font-family: 'Montserrat Alternates', sans-serif;
}

body {
  height: 100%;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1.5rem;
  line-height: 1.87rem;
  background-color: #e5e5e5;
}

img {
  max-width: 100%;
  height: auto;
}

label {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #f4f5f7;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#root {
  width: 100%;
  height: 100%;

  *:focus-visible {
    outline: none;
  }
}

canvas {
  position: absolute;
}
