/** Grid */
@mixin grid($columns: 2, $x-gutter: 16px, $y-gutter: 16px) {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{$x-gutter});
  margin-top: -$y-gutter;
  margin-left: -$x-gutter;

  > * {
    width: calc(1 / #{$columns} * 100% - #{$x-gutter});
    margin-top: $y-gutter;
    margin-left: $x-gutter;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/** Typography */
$typography-base-font-size: 1.53rem;

@mixin typography-base {
  font-size: $typography-base-font-size;
  font-weight: 700;
}

@mixin typography-base-capitalize {
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }
}

/** CSS effects */
@mixin main-clipping {
  clip-path: polygon(0% 0%, 98.5% 1.5%, 100% 98.5%, 1.5% 100%);
}

@mixin secondary-clipping {
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 6% 100%);
}

@mixin text-clip($rows: 2) {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: #{$rows * 2}rem;
  line-height: 2rem;
}

@mixin transition($property: all) {
  transition: 200ms $property;
}

@mixin fade($height: 60px) {
  z-index: 1;
  display: inline;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: $height;
  background: linear-gradient(rgb(0 0 0 / 0%), #fff);
}

@mixin fade-top($height: 60px) {
  @include fade($height);

  top: -$height;
}

@mixin hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
